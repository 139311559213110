export type EmailObject = {
  id: number;
  email: string;
};

export const emails: EmailObject[] = [
  { id: 1, email: 'support@cosmetic.college' },
  { id: 2, email: 'sales@cosmetic.college' },
  { id: 3, email: 'vtct@cosmetic.college' },
  { id: 4, email: 'models@cosmetic.college' },
  { id: 5, email: 'aesthetics@cosmetic.college' },
  { id: 6, email: 'beauty@cosmetic.college' },
  { id: 7, email: 'pmu@cosmetic.college' },
  { id: 8, email: 'management@cosmetic.college' },
];
